import Vue from 'vue'
import Vuetify, {
  VCard,
  VCardTitle,
  VCardActions,
  VCardText,
  VSpacer,
  VDivider,
  VProgressLinear,
  VForm,
  VTabs,
  VTabsItems,
  VRadio,
  VRadioGroup,
  VTab,
  VTabItem,
  VTabsSlider,
  VBtn,
  VTextField,
  VRow,
  VCol,
  VSelect,
  VCarouselItem,
  VCarousel,
  VAutocomplete,
  VStepper,
  VStepperContent,
  VStepperHeader,
  VStepperItems,
  VStepperStep,
  VColorPickerInput,
  VCheckbox,
  VImg,
  VSlider,
  VTextarea,
  VIcon,
  VExpansionPanelContent,
  VExpansionPanel,
  VExpansionPanels,
  VExpansionPanelHeader,
  VSchemaRenderer,
  VParagraph,
  // admin
  // VSchemaBuilder,
  // VCrudColumnTimestamp,
  // VTimestampInput,
} from 'vuetify/lib'
import { Ripple } from 'vuetify/lib/directives'

import en from 'vuetify/lib/locale/en'
import fa from 'vuetify/lib/locale/fa'

Vue.use(Vuetify, {
  components: {
    VCard,
    VCardTitle,
    VCardActions,
    VCardText,
    VSpacer,
    VDivider,
    VProgressLinear,
    VForm,
    VTabs,
    VTabsItems,
    VTab,
    VTabItem,
    VTabsSlider,
    VBtn,
    VTextField,
    VRow,
    VCol,
    VAutocomplete,
    VCarouselItem,
    VSelect,
    VStepper,
    VStepperContent,
    VStepperHeader,
    VStepperItems,
    VStepperStep,
    VCarousel,
    VImg,
    VRadio,
    VRadioGroup,
    VIcon,
    VSlider,
    VTextarea,
    VColorPickerInput,
    VCheckbox,
    VExpansionPanelContent,
    VExpansionPanel,
    VExpansionPanels,
    VExpansionPanelHeader,
    VParagraph,
    VSchemaRenderer,
    // admin components
    // VSchemaBuilder,
    // VCrudColumnTimestamp,
    // VTimestampInput,
  },
  directives: {
    Ripple,
  },
})

export default new Vuetify({
  rtl: true,
  lang: {
    locales: { en, fa },
    current: 'fa',
  },
  theme: {
    options: {
      customProperties: true,
    },
    dark: true,
  },
})
