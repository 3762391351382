import './sass/frontend.scss'
import './sass/backend.scss'

import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './plugins/router'
import vuetify from './plugins/vuetify.website'
import press from './plugins/press.website'
import i18n from './plugins/i18n'

import './plugins/echo'
import './plugins/externals'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  vuetify,
  $press: press(store),
  render: h => h(App),
}).$mount('#app')
