export default {
  name: 'FormSubmit',
  description: 'Submit forms to API endpoints',
  attributes: [
    {
      name: 'formId',
      description: 'FormId to automatically generate other settings from a form in Database',
      value: {
        kind: 'expression',
        type: 'number',
      },
    },
    {
      name: 'url',
      description: 'Submit url endpont',
      value: {
        kind: 'expression',
        type: 'string',
      },
    },
    {
      name: 'method',
      description: 'Submit method (one of: GET, POST, PUT, DELETE)',
      value: {
        kind: 'expression',
        type: 'string',
      },
    },
    {
      name: 'tag',
      description: 'Root tag, default is div',
      value: {
        kind: 'expression',
        type: 'string',
      },
    },
    {
      name: 'onlyUsers',
      description: 'Allow only signed in users to submit this form.',
      default: 'false',
      value: {
        kind: 'expression',
        type: 'boolean',
      },
    },
    {
      name: 'showDivider',
      description: 'Show a divider between default slot and actions.',
      default: 'false',
      value: {
        kind: 'expression',
        type: 'boolean',
      },
    },
    {
      name: 'submitLabel',
      description: 'Sets submit button label',
      value: {
        kind: 'expression',
        type: 'string',
      },
    },
    {
      name: 'successMessage',
      description: 'Show this message when form is submitted successfully',
      value: {
        kind: 'expression',
        type: 'string',
      },
    },
  ],
  events: [
  ],
  slots: [
    {
      name: 'default',
      description: 'The default Vue slot.',
    },
    {
      name: 'action',
      description: 'Customize submit action. use eval("this.$refs.form.submitForm()") as your button action',
    },
  ],
  'vue-model': {
    prop: 'value',
    event: 'input',
  },
  factory: () => ({
    component: import('./FormSubmit.vue'),
  }),
}
