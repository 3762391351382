import AdminRoutes from '@peynman/press-vue-admin/routes'

const ShopAsyncComponentLoad = () => import(/* webpackChunkName: "shop-home" */'./views/Shop.vue')
const ShopCategoryAsyncComponentLoad = () => import(/* webpackChunkName: "shop-category" */'./views/ShopCategory.vue')

export default [
  ...(process.env.NODE_ENV !== 'production'
    ? [{
      path: '/dev',
      component: () => import('./dev/Playground.vue'),
    }]
    : []),
  {
    path: '/404',
    component: () => import(/* webpackChunkName: "404" */'./views/404.vue'),
  },
  {
    path: '/500',
    component: () => import(/* webpackChunkName: "500" */'./views/500.vue'),
  },
  {
    path: '/signin',
    component: () => import(/* webpackChunkName: "signin" */'./views/Signin.vue'),
  },
  {
    path: '/signup',
    component: () => import(/* webpackChunkName: "signup" */'./views/Signup.vue'),
  },
  {
    path: '/me/profile',
    component: () => import(/* webpackChunkName: "profile" */'./views/Profile.vue'),
  },
  {
    path: '/me/cart',
    component: () => import(/* webpackChunkName: "current-cart" */'./views/PurchasingCart.vue'),
  },
  {
    path: '/me/cart/verify/:gateway',
    component: () => import(/* webpackChunkName: "cart-verify" */'./views/PurchasingCartVerify.vue'),
    props: route => {
      return {
        gateway: route.params.gateway,
      }
    },
  },
  {
    path: '/me/change-password',
    component: () => import(/* webpackChunkName: "change-password" */'./views/ChangePassword.vue'),
  },
  {
    path: '/me/wallet',
    component: () => import(/* webpackChunkName: "wallet-history" */'./views/WalletHistory.vue'),
  },
  {
    path: '/me/carts',
    component: () => import(/* webpackChunkName: "cart-history" */'./views/PurchaseHistory.vue'),
  },
  {
    path: '/me/chats',
    component: () => import(/* webpackChunkName: "chats" */'./views/Chats.vue'),
  },
  {
    path: '/contact',
    component: () => import(/* webpackChunkName: "contact-us" */'./views/ContactUs.vue'),
  },
  {
    path: '/branches',
    component: () => import(/* webpackChunkName: "branches" */'./views/ContactUs.vue'),
  },
  {
    path: '/shop',
    component: ShopAsyncComponentLoad,
    props: route => {
      return {
        page: 1,
        pagedPathPrefix: '/shop/',
      }
    },
  },
  {
    path: '/shop/:page',
    component: ShopAsyncComponentLoad,
    props: route => {
      return {
        page: parseInt(route.params.page),
        pagedPathPrefix: '/shop/',
      }
    },
  },
  {
    path: '/shop/category/:cat',
    component: ShopCategoryAsyncComponentLoad,
    props: route => {
      return {
        categoryId: parseInt(route.params.cat),
        page: 1,
      }
    },
  },
  {
    path: '/shop/category/:cat/:page',
    component: ShopCategoryAsyncComponentLoad,
    props: route => {
      return {
        categoryId: parseInt(route.params.cat),
        page: parseInt(route.params.page),
      }
    },
  },
  {
    path: '/shop/product/:prod',
    component: () => import(/* webpackChunkName: "shop-product" */'./views/ProductPage.vue'),
    props: route => {
      return {
        objectId: route.params.prod,
      }
    },
  },
  {
    path: '/search',
    component: () => import(/* webpackChunkName: "search" */'./views/Search.vue'),
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "home" */'./views/Home.vue'),
  },
  ...AdminRoutes,
  {
    path: '*',
    name: 'global',
    component: () => import(/* webpackChunkName: "remote-page" */'@peynman/press-vue-admin/pages/RemotePage'),
  },
]
