import ProductsCarouselSchema from './Product/ProductsCarousel/schema'
import FormSubmitSchema from './FormSubmit/schema'
import InlineEditableTable from './ThemeInput/inlineEditableTable'

const Components = [
  ProductsCarouselSchema,
  FormSubmitSchema,
  InlineEditableTable,
]

export default Components

export const ComponentsDictionary = Components.reduce((dic, c) => {
  dic[c.name] = c.factory
  return dic
}, {})
