export default {
  name: 'ProductsCarousel',
  description: '',
  attributes: [
    {
      name: 'color',
      description: 'Applies specified color to the control - it can be the name of material color (for example `success` or `purple`) or css color (`#033` or `rgba(255, 0, 0, 0.5)`). You can find list of built in classes on the [colors page](/styles/colors#material-colors).',
      default: 'undefined',
      value: {
        kind: 'expression',
        type: 'string',
      },
    },
    {
      name: 'dark',
      description: 'Applies the dark theme variant to the component. You can find more information on the Material Design documentation for [dark themes](https://material.io/design/color/dark-theme.html).',
      default: 'false',
      value: {
        kind: 'expression',
        type: 'boolean',
      },
      type: 'boolean',
    },
    {
      name: 'light',
      description: 'Applies the light theme variant to the component.',
      default: 'false',
      value: {
        kind: 'expression',
        type: 'boolean',
      },
      type: 'boolean',
    },
    {
      name: 'carouselColor',
      description: 'Applies specified color to the control - it can be the name of material color (for example `success` or `purple`) or css color (`#033` or `rgba(255, 0, 0, 0.5)`). You can find list of built in classes on the [colors page](/styles/colors#material-colors).',
      default: 'undefined',
      value: {
        kind: 'expression',
        type: 'string',
      },
    },
    {
      name: 'carouselDark',
      description: 'Applies the dark theme variant to the component. You can find more information on the Material Design documentation for [dark themes](https://material.io/design/color/dark-theme.html).',
      default: 'false',
      value: {
        kind: 'expression',
        type: 'boolean',
      },
      type: 'boolean',
    },
    {
      name: 'carouselLight',
      description: 'Applies the light theme variant to the component.',
      default: 'false',
      value: {
        kind: 'expression',
        type: 'boolean',
      },
      type: 'boolean',
    },
    {
      name: 'show-full-card',
      description: 'Show product cards',
      default: 'false',
      value: {
        kind: 'expression',
        type: 'boolean',
      },
      type: 'boolean',
    },
    {
      name: 'show-arrows',
      description: 'Change when the overflow arrow indicators are shown. By **default**, arrows *always* display on Desktop when the container is overflowing. When the container overflows on mobile, arrows are not shown by default. A **show-arrows** value of `true` allows these arrows to show on Mobile if the container overflowing. A value of `desktop` *always* displays arrows on Desktop while a value of `mobile` always displays arrows on Mobile. A value of `always` always displays arrows on Desktop *and* Mobile. Find more information on how to customize breakpoint thresholds on the [breakpoints page](/customizing/breakpoints).',
      default: 'undefined',
      value: {
        kind: 'expression',
        type: [
          'boolean',
          'string',
        ],
      },
    },
    {
      name: 'delimiter-icon',
      description: 'Sets icon for carousel delimiter',
      default: "'$delimiter'",
      value: {
        kind: 'expression',
        type: 'string',
      },
    },
    {
      name: 'hide-delimiter-background',
      description: 'Hides the bottom delimiter background.',
      default: 'true',
      value: {
        kind: 'expression',
        type: 'boolean',
      },
      type: 'boolean',
    },
    {
      name: 'label',
      description: 'Sets label',
      value: {
        kind: 'expression',
        type: 'string',
      },
    },
    {
      name: 'elevation',
      description: 'Designates an elevation applied to the component between 0 and 24. You can find more information on the [elevation page](/styles/elevation).',
      default: 'undefined',
      value: {
        kind: 'expression',
        type: [
          'number',
          'string',
        ],
      },
    },
    {
      name: 'sm',
      description: 'Changes the number of columns on small and greater breakpoints.',
      default: 'false',
      value: {
        kind: 'expression',
        type: [
          'boolean',
          'string',
          'number',
        ],
      },
    },
    {
      name: 'lg',
      description: 'Changes the number of columns on large and greater breakpoints.',
      default: 'false',
      value: {
        kind: 'expression',
        type: [
          'boolean',
          'string',
          'number',
        ],
      },
    },
    {
      name: 'xl',
      description: 'Changes the number of columns on extra large and greater breakpoints.',
      default: 'false',
      value: {
        kind: 'expression',
        type: [
          'boolean',
          'string',
          'number',
        ],
      },
    },
    {
      name: 'md',
      description: 'Changes the number of columns on medium and greater breakpoints.',
      default: 'false',
      value: {
        kind: 'expression',
        type: [
          'boolean',
          'string',
          'number',
        ],
      },
    },
    {
      name: 'categories',
      description: 'Select categories to load on slider',
      value: {
        kind: 'expression',
        type: 'custom',
      },
      tag: 'ProductCategoriesInput',
      factory: () => ({
        component: import('@peynman/press-vue-admin/components/Inputs/ProductCategoriesInput/ProductCategoriesInput.vue'),
      }),
      props: {
        returnIds: true,
      },
    },
  ],
  events: [],
  slots: [
    {
      name: 'default',
      description: 'The default Vue slot.',
    },
    {
      name: 'title',
      description: 'Show in carousel card title.',
    },
  ],
  'vue-model': {
    prop: 'value',
    event: 'input',
  },
  factory: () => ({
    component: import('./ProductsCarousel.vue'),
  }),
}
