export default {
  name: 'InlineEditableTable',
  description: '',
  attributes: [
    {
      name: 'headers',
      description: 'An array of objects for each header column. headers need title & key',
      default: '[]',
      value: {
        kind: 'expression',
        type: 'array',
      },
    },
    {
      name: 'label',
      description: 'The **aria-label** used for the badge',
      default: "'$vuetify.badge'",
      value: {
        kind: 'expression',
        type: 'string',
      },
    },
    {
      name: 'value',
      description: "The input's value",
      value: {
        kind: 'expression',
        type: 'any',
      },
    },
  ],
  events: [
    {
      name: 'input',
      description: 'The updated bound model',
      arguments: [
        {
          name: 'argument',
          type: 'boolean',
        },
      ],
    },
  ],
  slots: [

  ],
  'vue-model': {
    prop: 'value',
    event: 'input',
  },
  factory: () => ({
    component: import('./InlineEditableTable.vue'),
  }),
}
