<template>
  <div id="app">
    <v-app>
      <app-snackbar />
      <router-view />
    </v-app>
  </div>
</template>

<script>
  import AdminTheme from '@peynman/press-vue-admin/theme'
  import { mergeDeep } from '@peynman/press-vue-core/utils/helpers'
  import PressApp from '@peynman/press-vue-core/mixins/PressApp'
  import WebsiteTheme from './theme'
  import Themeable from '@peynman/press-vue-core/mixins/Themeable'
  import AppSnackbar from '@peynman/press-vue-admin/components/PaasApp/AppSnackbar.vue'

  export default {
    components: {
      AppSnackbar,
    },
    mixins: [
      PressApp,
      Themeable,
    ],
    created () {
      this.$store.commit('theme/appendTheme', mergeDeep(AdminTheme, WebsiteTheme))
      if (window.PageConfig?.schema?.schema?.theme) {
        this.$store.commit('theme/appendTheme', window.PageConfig.schema.schema.theme)
      }
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        this.$vuetify.theme.dark = true
      }
      window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
        this.$vuetify.theme.dark = event.matches
      })
      if (!this.$vuetify.theme.isDark) {
        const hours = new Date().getHours()
        this.$vuetify.theme.dark =
          hours < (this.theme.website?.dayTime?.from ?? 6) &&
          hours >= (this.theme.website?.dayTime?.to ?? 20)
      }
      this.$store.commit('analytics/setDimentionValue', { dimId: 2, value: this.$vuetify.theme.isDark })
      this.$router.afterEach((to, from) => {
        if (from.fullPath !== to.fullPath) {
          this.$store.dispatch('analytics/trackPageVisit')
        }
      })
    },
  }
</script>
