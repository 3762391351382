import TemplateWebsite from './Website/schema'

const Templates = [
  TemplateWebsite,
]

export default Templates

export const TemplatesDictionary = Templates.reduce((dic, c) => {
  dic[c.name] = c.factory
  return dic
}, {})
