// farsi messages
export default {
  fa: {
    components: {
      website: {
        appbar: {
          title: 'مدنا',
          auth: 'ورود یا ثبت‌نام',
          shop: 'فروشگاه مدنا',
        },
        footer: {
          title: 'فروشگاه اینترنتی مدنا',
          info: 'اطلاعات مدنا',
          customers: 'خدمات مشتریان',
          copyright: 'تمام حقوق سایت متعلق به مدنا میباشد - ۱۴۰۰',
          whatsapp: 'ارتباط با پشتیبانی در واتس‌اپ',
          instagram: 'صفحه اینستاگرام مدنا',
          social: 'ما را در شبکه های اجتماعی دنبال کنید',
          contact: 'شماره‌های تماس',
          location: 'آدرس',
          addressJordan: 'جردن بين جهان كودك و ميرداماد خيابان پديدار پلاك ٢٤ واحد ٢',
          addressSaadat: 'سعادت اباد بلوار کوهستان خیابان امام صادق پلاک ۲۵',
          contacts: {
            1: 'مزون جردن',
            2: 'فروشگاه سعادت‌آباد',
            3: 'پشتیبانی واتس‌اپ',
          },
          faq: 'سوالات متداول',
          themeSwitch: 'تغییر تم سایت بین تاریک و روشن',
        },
        breadcrumbs: {
          home: 'خانه',
          shop: 'فروشگاه',
        },
        search: {
          label: 'جستجو در سایت',
          search: 'جستجو',
        },
        faqs: {
          title: 'سوالات متداول',
          questions: {
            1: 'هزینه ارسال به چه صورت است؟',
            2: 'خریدم چه زمانی به دست من می‌رسد؟',
            3: 'اگر در هنگام تحویل کسی در منزل نباشد چه اتقاقی خواهد افتاد؟',
            4: 'ساعات کار مزون چه روز‌ها و ساعت‌هایی است؟',
            5: 'برای مراجعه حضوری نیاز به تایین وقت و رزور است؟',
            6: '',
          },
          answers: {
            1: 'برای تهران فیلان برای غیره بیسار',
          },
        },
        navigation: {
          home: 'خانه سایت',
          signin: 'ورود به حساب کاربری',
          signup: 'ثبت نام',
          exit: 'خروج از این نام‌کاربری',
          about: 'درباره ما',
          support: 'پشتیبانی',
          contact: 'تماس با ما',
          shop: 'فروشگاه مدنا',
          admin: 'پنل ادمین',
          adminMenu: 'گزینه‌های پنل ادمین',
          return: 'بستن منو',
          search: 'جستجو در فروشگاه',
          branches: 'شعب مدنا',
        },
        signin: {
          title: 'ورود به سایت',
          submit: 'ورود',
          forgotPassword: 'بازیابی رمز عبور',
          signinWithVerificationCode: 'ورود با یکبار رمز',
          username: 'شماره همراه',
          usernameHint: 'شماره همراه را با اعداد انگلیسی و شروع ۰۹ وارد کنید',
          password: 'رمز عبور',
          sendOTC: 'ارسال رمز یکبار مصرف',
          withPassword: 'ورود با رمز ثبت نام',
          signupMessage: 'حساب کاربری ندارید؟',
          signup: 'ثبت نام کنید',
        },
        signup: {
          title: 'ثبت نام در سایت',
          verifyMessage: 'برای ثبت نام، ابتدا شماره همراه خود را تایید کنید',
          phone: 'شماره همراه',
          phoneHint: 'شماره همراه را با حروف انگلیسی و شروع ۰۹ وارد کنید',
          verifySubmit: 'ارسال کد تایید',
          viewTerms: 'مشاهده قوانین و مقررات',
          acceptTerms: 'قوانین و مقررات را مشاهده کرده و قبول دارم',
          code: 'کد تایید',
          codeHint: 'کد تایید که برای شما پیامک شد را وارد کنید',
          verifyCheck: 'تایید شماره همراه',
          username: 'نام کاربری',
          usernameHint: 'نام کاربری باید حداقل ۶ کاراکتر و بدون فاصله با حروف انگلیسی باشد',
          password: 'رمز عبور',
          passwordHint: 'رمز عبور حداقل باید شامل ۶ کاراکتر باشد',
          passwordConfirmation: 'تکرار رمز عبور',
          register: 'تکمیل ثبت نام',
          resetPassword: 'تغییر رمز',
          firstname: 'نام',
          firstnameHint: 'نام خود را با حروف فارسی وارد کنید',
          lastname: 'نام خانوادگی',
          lastnameHint: 'نام خانوادگی خود را با حروف فارسی وارد کنید',
          signin: 'وارد حساب کاربری شوید',
          signinMessage: 'قبلا ثبت نام کرده‌اید',
        },
        userCart: {
          title: 'سبد خرید شما',
          empty: 'سبد خرید شما خالی است',
          showDetails: 'مشاهده جزییات و صفحه پرداخت',
          remove: 'حذف از سبد خرید',
          cartAmount: 'مبلغ سبد',
          cartShipping: 'هزینه ارسال و بسته‌بندی',
          cartPayable: 'مبلغ قابل پرداخت',
          cartOff: 'ملبغ تخفیف',
          promotionsOff: 'مبلغ هدیه',
          giftCodeLabel: 'کد تخفیف',
          giftCodeSubmit: 'اعمال کد تخفیف',
          vetifyCart: 'تایید و بررسی‌نهایی',
          goToBank: 'تایید و انتقال به بانک',
          registerAndCheckout: 'تکمیل فرم ثبت‌نام و انتخاب نحوه ارسال',
          alreadyHaveAccount: 'قبلا ثبت نام کرده‌اید؟',
          signin: 'وارد شوید',
          pickAddress: 'انتخاب آدرس',
          quantity: 'تعداد',
          chooseAddressFirst: 'ابتدا آدرس تحویل را انتخاب کنید',
          deliveryAgent: 'نحوه ارسال',
          bankGateway: 'درگاه پرداخت',
          seeDeliveryFaqs: 'شرایط ارسال',
          giftSuccess: 'کد تخفیف اعمال شد',
          fillFormFirst: 'ابتدا فرم اطلاعات کاربری را تکمیل کنید',
          agents: {
            tehran_pishtaz: 'ارسال تهران',
            tehran_snap: 'ارسال تهران (فوری)',
            shahrestan_pishtaz: 'ارسال شهرستان',
            shahrestan_tipax: 'ارسال شهرستان (تيپاكس)',
            poste_pishtaz: 'poste_pishtaz',
          },
          agentHints: {
            tehran_pishtaz: 'بسته شما ظرف ۴۸ ساعت کاری از طریق پست پیشتاز به دست شما خواهد رسيد',
            tehran_snap: 'بسته شما كمتر از ٢ ساعت در ساعات كاري توسط اسنپ باكس به دست شما خواهد رسيد و هزينه ارسال به عهده مشتري ميباشد',
            shahrestan_pishtaz: 'بسته شما بين ٣ تا ٥ روز کاری از طريق پست پيشتاز به دست شما خواهد رسيد.',
            shahrestan_tipax: 'بسته شما بين ١ تا ٢ روز کاری به دست شما خواهد رسيد و هزينه ارسال به عهده مشتری ميباشد.',
            poste_pishtaz: 'poste_pishtaz',
          },
        },
        userCartVerify: {
          title: 'تایید نهایی سبد خرید',
          address: 'آدرس تحویل',
          deliveryAgent: 'نحوه ارسال',
          seeDeliveryFaqs: 'شرایط ارسال',
          forwarding: 'در حال انتقال به بانک',
          postalCode: 'کد پستی: {0}',
        },
        cartsHistory: {
          title: 'تاریخچه سفارش‌های شما',
          empty: 'گزینه ای پیدا نشد',
          loadMore: 'نمایش خرید‌های قدیمی‌تر',
        },
        cartItem: {
          title: 'سبدخرید با شناسه {id}',
          timestamp: 'ثبت شده در {timestamp}',
          details: 'جزییات سبد',
          support: 'پیام به پشتیبانی',
          notSentToBranch: 'در انتظار تایید انبار',
          sentToBranch: 'تایید در انبار',
          setnToBranchTooltip: 'خرید شما در انبار ثبت شده و بزودی برای شما ارسال خواهد شد',
          sentByBranch: 'ارسال شده توسط انبار در تاریخ {timestamp}',
          notSentByBranch: 'در انتظار ارسال',
          detailsTitle: 'جزییات سبد با شناسه {id}',
        },
        profileDetails: {
          title: 'تغییر اطلاعات پروفایل شما',
          profilePic: 'عکس پروفایل',
          firstname: 'نام',
          firstnameHint: 'لطفا نام خود را با حروف فارسی وارد کنید',
          lastname: 'نام خانوادگی',
          lastnameHint: 'لطفا نام خانوادگی خود را با حروف فارسی وارد کنید',
          gender: 'جنسیت',
          submit: 'ثبت و ارسال اطلاعات',
        },
        profileAddresses: {
          title: 'آدرس های شما',
          addAddress: 'افزودن آدرس جدید',
          removeAddress: 'حذف این آدرس',
          country: 'کشور',
          state: 'استان',
          city: 'شهر',
          address: 'آدرس دقیق',
          addressHint: 'لطفا آدرس دقیق خود را وارد کنید',
          buildingNum: 'پلاک',
          postalCode: 'کد پستی',
          location: 'محل دقیق روی نقشه',
        },
        profilePhones: {
          title: 'شماره ‌های تماس شما',
          contactPhones: 'شماره تماس فعلی',
          addPhone: 'افزودن شماره تماس جدید',
          removePhone: 'حذف شماره تماس',
        },
        profileEmail: {
          title: 'پست الکترونیک',
        },
        shopFilter: {
          title: 'فیلتر کردن کالاهای فروشگاه',
          categoriesFilter: 'دسته بندی کالا‌ها',
          removeFilters: 'نمایش از همه دسته بندی‌ها',
          sort: 'مرتب سازی براساس',
          apply: 'بروز رسانی کالا‌ها',
          priceLabel: 'محدوده قیمت',
          sortOptions: {
            publish_at_desc: 'جدیدترین',
            price_desc: 'گران‌ترین',
            price_asc: 'ارزان‌ترین',
            purchases_desc: 'محبوب‌ترین',
            stars_desc: 'بیشترین امتیاز',
          },
        },
        shop: {},
        productCard: {
          imageAlt: '{title} - اندازه:{size} رنگ:{color} - {categories}',
          details: 'انتخاب کزینه‌ها',
          favorite: 'مورد علاقه من',
          share: 'اشتراک گزاری',
          addToCart: 'افزودن به سبد خرید',
          addToCartSuccess: 'این کالا به سبد خرید شما اضافه شد',
          remove: 'حذف از سبد',
          removeSuccess: 'کالا از سبد خرید شما حذف شد',
          availableSizes: 'انتخاب سایز',
          availableColors: 'انتخاب رنگ',
          visit: 'جزییات بیشتر',
          pickedLabel: 'در اندازه {size} و رنگ {color}',
          pickedLabelAfter: 'انتخاب شده',
          pickLabel: 'اندازه و رنگ را انتخاب کنید',
          price: '{amount} {currency}',
          guide: 'مشاهده راهنمای سایز',
          notAvailableInStock: 'موجود نیست',
          availableInStock: 'موجود در انبار',
          contactMeWhenAvailable: 'موجود شد به من اطلاع بده',
          contactMeWhenAvailableSuccess: 'درخواست برای شما ثبت شد. با موجود شدن کالا به شما اطلاع داده خواهد شد.',
          quantity: 'تعداد {n} عدد',
          purchase: 'خرید',
          continueShopping: 'بستن و ادامه خرید',
          admin: {
            refreshGiv: 'بروز رسانی اطلاعات از گیو',
            edit: 'ویرایش کالا',
          },
        },
        productReviews: {
          title: 'نظرات کاربران',
          empty: 'نظری ثبت نشده است',
          yourReview: 'نظر خود را در رابطه با این محصول ثبت کنید',
          name: 'نام شما',
          nameHint: 'نام شما در قسمت نظرات نمایش داده میشود.',
          message: 'نظر',
          submit: 'ارسال نظر',
          rate: 'امتیاز شما به این محصول',
          loginFirst: 'برای ثبت نظر ابتدا وارد شوید و یا ثبت‌نام کنید',
          alreadySent: 'از این که نظر خود را به اشتراک گذاشتید از شما سپساس گزاریم',
          editReview: 'ویرایش این نظر',
          updateTitle: 'نظر خود را ویرایش کنید',
          loadMoreReviews: 'بارگزاری نظرات بیشتر',
        },
        walletCard: {
          title: 'تاریخچه خرید‌ها و پرداخت‌ها',
          empty: 'موردی برای نمایش یافت نشد',
          loadMore: 'بارگزاری رکورد‌های قدیمی‌تر',
        },
        changePassword: {
          title: 'تغییر رمز عبور',
          oldPassword: 'رمز عبور قدیمی',
          newPassword: 'رمز عبور جدید',
          confirmPassword: 'تکرار رمز عبور جدید',
          submit: 'ارسال و تغییر رمز',
        },
        chat: {
          title: 'پیام‌های شما با پشتیبانی',
          addTooltip: 'ثبت پیام جدید در پشتیبانی',
          details: 'پیام‌ها',
          titleLabel: 'موضوع پیام',
          typeLabel: 'بخش مربوط',
          messageLabel: 'پیام شما',
          empty: 'پیامی وجود ندارد',
          submit: 'ارسال پیام',
          loadMore: 'بارکزاری پیام‌های قدیمی‌تر',
          closed: 'بسته شد',
          open: 'در حال پیگیری',
          close: 'بستن منو',
          types: {
            purchased_cart: 'خرید و ارسال',
            website: 'فنی و سایت',
            support: 'رضایت مشتری',
          },
        },
        terms: {
          title: 'قوانین و مقررات سایت',
          content: '',
          accept: 'قوانین و مقررات را می پذیرم',
        },
        formSubmit: {
          submit: 'ارسال',
        },
      },
    },
    pagesTitles: {
      contact: 'تماس با ما',
      shop: 'فروشگاه مدنا',
      category: 'فروشگاه مدنا - {0}',
    },
  },
}
