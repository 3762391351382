export default {
  light: {
    website: {
      daytime: {
        from: 6,
        to: 20,
      },
      vuetify: {
      },
      signinRedirectDelay: 500,
      formatting: {
        locale: 'fa',
        timestampFormat: {
          calendar: 'persian',
          numberingSystem: 'arab',
          day: 'numeric',
          month: 'long',
          year: 'numeric',
          timeZone: 'Asia/Tehran',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        },
        dateFormat: {
          calendar: 'persian',
          numberingSystem: 'arab',
          day: 'numeric',
          month: 'long',
          year: 'numeric',
          timeZone: 'Asia/Tehran',
        },
        timeFormat: {
          calendar: 'persian',
          numberingSystem: 'arab',
          timeZone: 'Asia/Tehran',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        },
      },
      featuredHeader: {
        show: true,
        color: '#D02629',
      },
      appbar: {
        color: 'white',
        unscrolledColor: 'transparent',
        dark: false,
        light: false,
        height: {
          xs: 56,
          default: 100,
        },
        hideToolbar: false,
        image: '/vendor/dist/img/logo-big-white.png',
        imageWidth: {
          default: 220,
          xs: 100,
        },
        menu: {
          dark: false,
          light: false,
          color: 'grey lighten-4',
        },
        account: {
          color: 'transparent',
          dark: false,
        },
        styles: [
          'direction: rtl !important',
          'margin-top: 48px !important',
        ],
      },
      navigation: {
        header: {
          height: 120,
          image: '/vendor/dist/img/logo-big-white.png',
          imageWidth: 220,
        },
        color: 'white',
        dark: false,
        light: false,
        right: true,
        menu: {
          dark: false,
          light: false,
          color: 'white',
        },
      },
      body: {
        fluid: false,
        mainClass: 'grey darken-2 pb-4',
        containerClass: 'container-body',
        inputs: {},
      },
      footer: {
        padless: true,
        dark: false,
        light: false,
        color: 'grey lighten-4',
        image: '/vendor/dist/img/logo-big-white.png',
        imageWidth: {
          default: 200,
          xs: 220,
          md: 300,
          lg: 320,
          xl: 340,
        },
      },
      cards: {
        dark: false,
        light: false,
        color: 'white',
        inputs: {},
        buttons: {
          submit: {
            color: 'green',
          },
        },
      },
      signing: {
        tabs: {
          centered: true,
          color: 'blue lighten-5',
          dark: true,
          light: false,
          background: 'grey darken-1',
        },
        header: 'grey lighten-5',
        footer: 'white',
        dark: false,
        light: false,
        color: 'white',
        dialogFade: 0.7,
        inputs: {},
      },
      shop: {
        perPage: 30,
        filter: {
          dark: false,
          light: false,
          color: 'white',
          applyColor: 'white',
          flat: true,
          tile: true,
          height: 60,
        },
        menu: {
          activatorColor: 'white',
          dark: false,
          light: false,
          color: 'white',
        },
      },
      userCart: {
        card: {
          dark: false,
          light: false,
          color: 'white',
        },
        menu: {
          dark: false,
          light: false,
          color: 'grey lighten-4',
          badge: 'primary',
          buttonActive: {
          },
          buttonInactive: {},
          buttonSubmit: {
            color: 'primary',
          },
        },
        list: {
          dark: false,
          light: false,
          color: 'grey lighten-3',
        },
      },
      product: {
        masonry: {
          cols: 12,
          sm: 4,
          md: 3,
          lg: 3,
          xl: 3,
        },
        card: {
          dark: false,
          light: true,
          color: 'grey lighten-5',
          priceTag: {
            dark: true,
            light: false,
            primary: 'grey',
            secondary: 'red darken-1',
          },
        },
      },
      chat: {
        card: {
          dark: true,
          light: false,
          color: 'bluegrey darken-4',
        },
        bubble: {
          color: 'black',
          dark: true,
          light: false,
        },
      },
    },
  },
  dark: {
    website: {
      vuetify: {
      },
      signinRedirectDelay: 500,
      formatting: {
        locale: 'fa',
        timestampFormat: {
          calendar: 'persian',
          numberingSystem: 'arab',
          day: 'numeric',
          month: 'long',
          year: 'numeric',
          timeZone: 'Asia/Tehran',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        },
        dateFormat: {
          calendar: 'persian',
          numberingSystem: 'arab',
          day: 'numeric',
          month: 'long',
          year: 'numeric',
          timeZone: 'Asia/Tehran',
        },
        timeFormat: {
          calendar: 'persian',
          numberingSystem: 'arab',
          timeZone: 'Asia/Tehran',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        },
      },
      featuredHeader: {
        show: true,
        color: '#FC3536',
        message: 'Featured Header',
      },
      appbar: {
        color: 'black',
        unscrolledColor: 'transparent',
        dark: true,
        light: false,
        height: {
          xs: 56,
          default: 100,
        },
        hideToolbar: false,
        image: '/vendor/dist/img/logo-big-black.png',
        imageWidth: {
          default: 240,
          xs: 100,
        },
        styles: [
          'direction: rtl !important',
          'margin-top: 48px !important',
        ],
        menu: {
          dark: false,
          light: false,
          color: 'grey darken-4',
        },
        account: {
          color: 'transparent',
          dark: false,
        },
      },
      navigation: {
        header: {
          height: 120,
          image: '/vendor/dist/img/logo-big-black.png',
          imageWidth: 220,
        },
        color: 'grey darken-4',
        dark: true,
        light: false,
        right: true,
        menu: {
          dark: true,
          light: false,
        },
      },
      body: {
        fluid: false,
        mainClass: 'grey darken-2 pb-4',
        containerClass: 'container-body',
        inputs: {},
      },
      footer: {
        padless: true,
        dark: false,
        light: false,
        color: 'black',
        image: '/vendor/dist/img/logo-big-black.png',
        imageWidth: {
          default: 200,
          xs: 220,
          md: 300,
          lg: 320,
          xl: 340,
        },
      },
      cards: {
        dark: true,
        light: false,
        color: 'grey darken-4',
        inputs: {},
        buttons: {
          submit: {
            color: 'primary',
          },
        },
      },
      signing: {
        tabs: {
          centered: true,
          color: 'blue lighten-5',
          dark: true,
          light: false,
          background: 'blue darken-4',
        },
        header: 'grey darken-4',
        footer: '',
        dark: true,
        light: false,
        color: '',
        dialogFade: 0.7,
        inputs: {},
      },
      shop: {
        perPage: 30,
        filter: {
          dark: false,
          light: false,
          color: 'grey darken-4',
          applyColor: 'secondary',
          menuBtnColor: 'orange darken-3',
          flat: true,
          tile: true,
          height: {
            default: 60,
            xs: 70,
          },
        },
        menu: {
          activatorColor: '',
          dark: true,
          light: false,
          color: 'grey darken-3',
        },
      },
      userCart: {
        card: {
          dark: false,
          light: false,
          color: 'grey darken-4',
        },
        menu: {
          dark: true,
          light: false,
          color: 'grey darken-4',
          badge: 'primary',
          buttonActive: {},
          buttonInactive: {},
          buttonSubmit: {
            color: 'primary',
          },
        },
        list: {
          dark: true,
          light: false,
          color: 'grey darken-3',
        },
      },
      product: {
        masonry: {
          cols: 12,
          sm: 4,
          md: 3,
          lg: 3,
          xl: 3,
        },
        card: {
          dark: true,
          light: false,
          color: 'grey darken-4',
          priceTag: {
            dark: false,
            light: true,
            primary: 'grey lighten-4',
            primaryText: 'black--text',
            secondary: 'red darken-1',
            secondaryText: 'red--text',
          },
        },
      },
      chat: {
        card: {
          dark: true,
          light: false,
          color: 'bluegrey darken-4',
        },
        bubble: {
          color: 'black',
          dark: true,
          light: false,
        },
      },
    },
  },
}
