export default {
  name: 'TemplateWebsite',
  description: '',
  attributes: [
    {
      name: 'mainClass',
      description: 'Main body class',
      default: 'false',
      value: {
        kind: 'expression',
        type: 'string',
      },
      type: 'string',
    },
    {
      name: 'containerClass',
      description: 'Container body class',
      default: 'false',
      value: {
        kind: 'expression',
        type: 'string',
      },
      type: 'string',
    },
    {
      name: 'appbarDark',
      description: 'Appbar Dark',
      default: 'false',
      value: {
        kind: 'expression',
        type: 'boolean',
      },
      type: 'boolean',
    },
    {
      name: 'appbarLight',
      description: 'Appbar Light',
      default: 'false',
      value: {
        kind: 'expression',
        type: 'boolean',
      },
      type: 'boolean',
    },
    {
      name: 'fluidContainer',
      description: 'Container fluid',
      default: 'false',
      value: {
        kind: 'expression',
        type: 'boolean',
      },
      type: 'boolean',
    },
    {
      name: 'changeBarColorOnScroll',
      description: 'Change appbar color on scroll',
      default: 'false',
      value: {
        kind: 'expression',
        type: 'boolean',
      },
      type: 'boolean',
    },
    {
      name: 'hideAppbar',
      description: 'Hide app bar',
      default: 'false',
      value: {
        kind: 'expression',
        type: 'boolean',
      },
      type: 'boolean',
    },
    {
      name: 'hideFooter',
      description: 'Hide footer',
      default: 'false',
      value: {
        kind: 'expression',
        type: 'boolean',
      },
      type: 'boolean',
    },
    {
      name: 'theme',
      description: 'Theme options',
      default: {},
      value: {
        kind: 'expression',
        type: 'custom',
      },
      type: 'custom',
      cantBind: true,
      tag: 'ThemeInput',
      // factory: () => import('../../../components/Admin/Inputs/ThemeInput/ThemeInput.vue'),
    },
  ],
  events: [
  ],
  slots: [
    {
      name: 'default',
      description: 'The default Vue slot.',
    },
    {
      name: 'top',
      description: 'Slot to show on top of appbar.',
    },
    {
      name: 'toolbar',
      description: 'Slot to show under the appbar',
    },
    {
      name: 'footer',
      description: 'Slot to show under the footer.',
    },
  ],
  'vue-model': {
    prop: 'value',
    event: 'input',
  },
  factory: () => ({
    component: import('./Website.vue'),
  }),
}
