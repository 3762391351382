import Vue from 'vue'
import PressCore from '@peynman/press-vue-core'
import { ComponentsDictionary } from '../components/schemas'
import { TemplatesDictionary } from '../templates/schemas'
import AdminComponentsDictionary from '../components/admin-schemas'

import CartStatusList from '@peynman/press-vue-core/crud/Cart/status'

export const SchemasDictionary = {
  ...ComponentsDictionary,
  ...TemplatesDictionary,
  ...AdminComponentsDictionary,
}

export default $store => (new PressCore(Vue, {
  store: $store,
  schemaTypesDictionary: $component => (SchemasDictionary),
  builderTypesDictionary: $component => ([]),
  crudImportCallback: crud => import('@peynman/press-vue-core/crud/' + crud),
  cartStatusList: $component => CartStatusList($component),
  chatRoomTypeList: $component => Object.entries($component.$t('component.website.chat.types')).map(e => ({
    text: e[1],
    value: e[0],
  })),
  deliveryAgentList: $component => Object.entries($component.$t('components.website.userCart.agents')).map(e => ({
    text: e[1],
    value: e[0],
    hint: $component.$t('components.website.userCart.agentHints.' + e[0]),
  })),
  importAsyncComponentCallback: name => {
    if (name === 'ThemeInput') {
      return import('../components/ThemeInput/ThemeInput.vue')
    }
    return SchemasDictionary[name]?.().component
  },
}))
